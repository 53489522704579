import React from "react";
import { Label, List, SemanticCOLORS, Statistic } from "semantic-ui-react";

interface ILeaderboardListProps {
  titleTop: string;
  titleBottom: string;
  orderedRows: { name: string; metric: string }[];
}

export const LeaderboardList: React.FunctionComponent<ILeaderboardListProps> = (
  props
) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Statistic>
        <Statistic.Value>{props.titleTop}</Statistic.Value>
        <Statistic.Label>{props.titleBottom}</Statistic.Label>
      </Statistic>
      <List as="ol">
        {props.orderedRows.map((row, index) => {
          let placementColor: SemanticCOLORS = "pink";
          if (index === 0) {
            placementColor = "violet";
          }
          if (index === 1) {
            placementColor = "purple";
          }

          return (
            <List.Item as="li">
              <List.Content>
                <List.Header>{row.name}</List.Header>
                <List.Description>
                  <Label size="big" color={placementColor}>
                    {row.metric}
                  </Label>
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};
