import React from "react";
import {
  Icon,
  SemanticCOLORS,
  SemanticICONS,
  Statistic,
  Step,
} from "semantic-ui-react";

interface ILeaderboardSummaryProps {
  titleTop: string;
  titleBottom: string;
  stepContent: {
    iconName: SemanticICONS;
    iconColor: SemanticCOLORS;
    title: string;
    subtitle: string;
  }[];
}

export const LeaderboardSummary: React.FunctionComponent<ILeaderboardSummaryProps> =
  (props) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Statistic>
          <Statistic.Value>{props.titleTop}</Statistic.Value>
          <Statistic.Label>{props.titleBottom}</Statistic.Label>
        </Statistic>
        <Step.Group vertical>
          {props.stepContent.map((stepInfo) => {
            return (
              <Step>
                <Icon name={stepInfo.iconName} color={stepInfo.iconColor} />
                <Step.Content>
                  <Step.Title>{stepInfo.title}</Step.Title>
                  <Step.Description>{stepInfo.subtitle}</Step.Description>
                </Step.Content>
              </Step>
            );
          })}
        </Step.Group>
      </div>
    );
  };
