import React, { useState } from "react";
import { Button, Input, InputOnChangeData } from "semantic-ui-react";
import { ThreeCommasAPI } from "../services/3-commas-api";

interface IAuthenticationProps {}

export const Authentication: React.FunctionComponent<IAuthenticationProps> = (
  props
) => {
  const [password, setPassword] = useState<string>("");
  const onPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setPassword(data.value);
  };
  const onSubmit = () => {
    ThreeCommasAPI.universallyAuthenticate(password).catch((err) => {
      alert("Wrong password");
      console.error({ err });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "5rem",
      }}
    >
      <h1>{"Welcome to 3commasHelper"}</h1>
      <h2>{"Enter the secret password"}</h2>
      <Input
        placeholder="Enter password"
        value={password}
        type="password"
        onChange={onPasswordChange}
      />
      <Button style={{ marginTop: "1rem" }} color="green" onClick={onSubmit}>
        {"Submit"}
      </Button>
    </div>
  );
};
