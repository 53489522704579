import React from "react";
import { Message } from "semantic-ui-react";

interface ICreditsProps {}

export const Credits: React.FunctionComponent<ICreditsProps> = (props) => {
  return (
    <Message visible>
      <Message.Header>{"Site Credits"}</Message.Header>
      <Message.Item>
        {`Creator: `}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/matthew-schmuckler/"
        >
          Matthew Schmuckler
        </a>
      </Message.Item>
      <Message.Item>
        {`Icons provided by `}
        <a target="_blank" rel="noreferrer" href="https://icons8.com/">
          Icons8
        </a>
      </Message.Item>
    </Message>
  );
};
