import React, { useEffect, useState } from "react";
import _ from "lodash";
import "semantic-ui-css/semantic.min.css";
import { BotsTableWrapper } from "./components/BotsTableWrapper";
import { Dimmer, Loader, Tab } from "semantic-ui-react";
import { LeaderboardWrapper } from "./components/LeaderboardWrapper";
import { IDealsForBots, IUserBotsAndActiveDealsInfo } from "./types";
import { ThreeCommasAPI } from "./services/3-commas-api";
import { Credits } from "./components/Credits";
import { Authentication } from "./components/Authentication";

function App() {
  const [isLoadingAuthData, setIsLoadingAuthData] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [usersBotsDealsInfo, setUsersBotsDealsInfo] =
    useState<IUserBotsAndActiveDealsInfo[]>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [botsClosedDealsData, setBotsClosedDealsData] = useState<IDealsForBots>(
    {}
  );
  useEffect(() => {
    setTimeout(() => {
      ThreeCommasAPI.onAuthStateChanged((user) => {
        if (!!user) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setIsLoadingAuthData(false);
      });
    }, 500);
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      ThreeCommasAPI.getAllUsersInfo().then((value) => {
        setUsersBotsDealsInfo(value);
        setIsLoadingData(false);
      });
    }
  }, [isAuthenticated]);

  const fetchBotsClosedDealsData = (
    botsToFetch: { userName: string; botId: string }[],
    callback: () => void
  ) => {
    // Filter out bots that've already been fetched
    const filteredBotsToFetch = botsToFetch.filter(
      (bot) => !botsClosedDealsData[bot.botId]
    );

    if (_.isEmpty(filteredBotsToFetch)) {
      callback();
    } else {
      ThreeCommasAPI.getClosedDealsForBots(
        filteredBotsToFetch,
        "finished",
        "closed_at",
        1000
      ).then((value) => {
        const newBotsClosedDealsData = _.merge(value.data, botsClosedDealsData);
        setBotsClosedDealsData(newBotsClosedDealsData);
        callback();
      });
    }
  };

  if (isLoadingAuthData) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  if (!isAuthenticated) {
    return <Authentication />;
  }

  if (isLoadingData || !usersBotsDealsInfo) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  const panes = [
    {
      menuItem: { key: "botsComparison", icon: "cubes", content: "Bots" },
      render: () => (
        <Tab.Pane>
          <BotsTableWrapper
            usersBotsDealsInfo={usersBotsDealsInfo}
            botsClosedDealsData={botsClosedDealsData}
            fetchBotsClosedDealsData={fetchBotsClosedDealsData}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "leaderboard", icon: "trophy", content: "Leaderboard" },
      render: () => (
        <Tab.Pane>
          <LeaderboardWrapper usersBotsDealsInfo={usersBotsDealsInfo} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: "credits", icon: "info", content: "Credits" },
      render: () => (
        <Tab.Pane>
          <Credits />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div className="App">
      <Tab panes={panes} />
    </div>
  );
}

export default App;
//<a target="_blank" href="undefined/icons/set/bot">Bot icon</a> icon by <a target="_blank" href="">Icons8</a>
