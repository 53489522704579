import moment from "moment";
import { LocalStorageKey } from "./constants";
import { IBotInfo } from "./types";

export const calculateMaxCurrencyUsed = (
  botInfo: IBotInfo
): { amount: number; currencyType: string } => {
  let maxCurrencyUsed = Number(botInfo.base_order_volume);
  let currentSafetyOrderAmount = Number(botInfo.safety_order_volume);
  for (let so = 0; so < botInfo.max_safety_orders; so++) {
    if (so > 0) {
      currentSafetyOrderAmount =
        currentSafetyOrderAmount *
        Number(botInfo.martingale_volume_coefficient);
    }
    maxCurrencyUsed = maxCurrencyUsed + currentSafetyOrderAmount;
  }

  // Multiply by number of active deals for composite bots
  maxCurrencyUsed = maxCurrencyUsed * Number(botInfo.max_active_deals);

  return {
    amount: maxCurrencyUsed,
    currencyType: botInfo.base_order_volume_type,
  };
};

export const setLocalStorageValue = (
  key: LocalStorageKey,
  value: string
): void => {
  localStorage.setItem(key, value);
};

export const getLocalStorageValue = (key: LocalStorageKey): string | null => {
  return localStorage.getItem(key);
};

/**
 *
 * @param scale Scale number from 0 to 1
 * @returns
 */
export const deriveGradientColor = (
  scale: number,
  rbg0: [r: number, b: number, g: number],
  rbg1: [r: number, b: number, g: number]
): string => {
  const w1 = scale;
  const w2 = 1 - w1;
  const rgb = [
    Math.round(rbg1[0] * w1 + rbg0[0] * w2),
    Math.round(rbg1[1] * w1 + rbg0[1] * w2),
    Math.round(rbg1[2] * w1 + rbg0[2] * w2),
  ];
  return "rgb(" + rgb.join(", ") + ")";
};

export const fillInDateRangeEmpties = (
  mostRecentDay: string | undefined,
  mostPastDay: string | undefined
): string[] => {
  const filledInDateRange: string[] = [];
  if (!mostRecentDay) {
    // No data
    return filledInDateRange;
  }

  filledInDateRange.push(mostRecentDay);
  if (mostRecentDay === mostPastDay) {
    // Only 1 day worth of data
    return filledInDateRange;
  }

  let dayBefore = moment(mostRecentDay)
    .subtract(1, "days")
    .format("MM/DD/YYYY");
  filledInDateRange.push(dayBefore);
  while (dayBefore !== mostPastDay) {
    dayBefore = moment(dayBefore).subtract(1, "days").format("MM/DD/YYYY");
    filledInDateRange.push(dayBefore);
  }

  return filledInDateRange;
};
