import moment from "moment";
import React, { useState } from "react";
import { Button, Icon, Label, List, Popup, Table } from "semantic-ui-react";
import { dollarCoins } from "../constants";
import { IBotStrategyListEntity } from "../types";
import { WarningPopup } from "./WarningPopup";

export interface IBotsTableRow {
  // BOT INFO
  addToComparison: (botId: string, userName: string) => void;
  isMarkedForComparison: boolean;
  userName: string;
  accountUsdAmount: number;
  botName: string;
  botId: number;
  createdAt: string;
  isEnabled: boolean;
  botType: string;
  finishedDealsProfitUsd: number;
  finishedDealsCount: number;
  pairs: string[]; // BTC_ETH
  strategy: string; // long/short
  baseOrderVolume: number;
  baseOrderVolumeType: string; // maybe add %
  safetyOrderVolume: number;
  safetyOrderVolumeType: string; // maybe add %
  strategyList: IBotStrategyListEntity[] | null | undefined; //start conditions
  takeProfit: number; // %
  takeProfitType: string;
  trailingDeviation?: number; // %
  maxSafetyOrders: number;
  safetyOrderStepPercentage: number; // %
  martingaleVolumeCoefficient: number; // %
  martingaleStepCoefficient: number; // %
  maxActiveDeals?: number;
  // DEALS INFO
  activeDeals: {
    createdAt: string;
    status: string;
    boughtAveragePrice: string;
    soldAveragePrice: string;
    currentPrice: string;
    takeProfitPrice: string;
    maxSafetyOrders: number;
    completedSafetyOrdersCounts: number;
    pair: string;
  }[];
}

interface IBotsTableProps {
  rows: IBotsTableRow[];
}

// TODO - avg # of safety orders hit

// TODO - what coin pairs are hot? Deeper composite bot view to see best pairs each day/week/month?
// Look at botName: "[USDT] Paper trading - 27 Pairs - Pinescript - 20/40 (5/9)"
// coin ranking with many simple bots, safe/medium/aggressive
//

export const BotsTable: React.FunctionComponent<IBotsTableProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>();
  // Sort by Total Profit
  const sortedRows = props.rows.sort((a, b) =>
    Number(a.finishedDealsProfitUsd) < Number(b.finishedDealsProfitUsd) ? 1 : -1
  );
  return (
    <Table celled compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing>#</Table.HeaderCell>
          <Table.HeaderCell collapsing>Compare</Table.HeaderCell>
          <Table.HeaderCell collapsing>Owner</Table.HeaderCell>
          <Table.HeaderCell collapsing>BotName</Table.HeaderCell>
          <Table.HeaderCell collapsing>Enabled</Table.HeaderCell>
          <Table.HeaderCell collapsing>Bagged</Table.HeaderCell>
          <Table.HeaderCell collapsing>Created</Table.HeaderCell>
          <Table.HeaderCell collapsing>Total Profit</Table.HeaderCell>
          <Table.HeaderCell collapsing>Total Profit %</Table.HeaderCell>
          <Table.HeaderCell collapsing>Avg Profit per Deal</Table.HeaderCell>
          <Table.HeaderCell collapsing># Deals Closed</Table.HeaderCell>
          <Table.HeaderCell collapsing>
            Avg Time per Deal <WarningPopup />
          </Table.HeaderCell>
          <Table.HeaderCell collapsing>Max Bot Usage</Table.HeaderCell>
          <Table.HeaderCell collapsing>Max Deviation Covered</Table.HeaderCell>
          <Table.HeaderCell collapsing>Long/Short</Table.HeaderCell>
          <Table.HeaderCell collapsing>Coins</Table.HeaderCell>
          <Table.HeaderCell collapsing>Base Order</Table.HeaderCell>
          <Table.HeaderCell collapsing>Safety Order</Table.HeaderCell>
          <Table.HeaderCell collapsing>Start Deal Conditions</Table.HeaderCell>
          <Table.HeaderCell collapsing>Max Active Deals</Table.HeaderCell>
          <Table.HeaderCell collapsing>Target Profit</Table.HeaderCell>
          <Table.HeaderCell collapsing>
            Trailing Profit Deviation
          </Table.HeaderCell>
          <Table.HeaderCell collapsing>Max Safety Orders</Table.HeaderCell>
          <Table.HeaderCell collapsing>Safety Order %</Table.HeaderCell>
          <Table.HeaderCell collapsing>
            Safety Order Volume Scale
          </Table.HeaderCell>
          <Table.HeaderCell collapsing>
            Safety Order Step Scale
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedRows.map((row, index) => {
          // Determine used currency
          const currencyBaseTypeString =
            row.baseOrderVolumeType === "percent"
              ? " %"
              : row.pairs[0].split("_")[0];

          // Calculate maximum currency used
          let maxCurrencyUsed = Number(row.baseOrderVolume);
          let currentSafetyOrderAmount = Number(row.safetyOrderVolume);
          for (let so = 0; so < row.maxSafetyOrders; so++) {
            if (so > 0) {
              currentSafetyOrderAmount =
                currentSafetyOrderAmount *
                Number(row.martingaleVolumeCoefficient);
            }
            maxCurrencyUsed = maxCurrencyUsed + currentSafetyOrderAmount;
          }
          maxCurrencyUsed = maxCurrencyUsed * Number(row.maxActiveDeals);

          // Calculate maximum deviation covered
          let maxDeviationCovered = Number(row.safetyOrderStepPercentage);
          let currentSafetyDeviationAmount = Number(
            row.safetyOrderStepPercentage
          );
          for (let so = 0; so < row.maxSafetyOrders; so++) {
            if (so > 0) {
              currentSafetyDeviationAmount =
                currentSafetyDeviationAmount *
                Number(row.martingaleStepCoefficient);
            }
            maxDeviationCovered =
              maxDeviationCovered + currentSafetyDeviationAmount;
          }

          // Calculate days ago creation
          const daysAgoCreation = moment().diff(row.createdAt, "days");

          // Calculate avg deal time in minutes
          const avgDealsTimeInMinutes =
            1 / (Number(row.finishedDealsCount) / (daysAgoCreation * 1440));

          // Calculate total % profit
          let totalPercentProfit;
          if (row.baseOrderVolumeType === "percent") {
            const maxCurrencyUsedTranslatedToUsd =
              (maxCurrencyUsed * row.accountUsdAmount) / 100;
            totalPercentProfit =
              (row.finishedDealsProfitUsd / maxCurrencyUsedTranslatedToUsd) *
              100;
          } else {
            totalPercentProfit =
              (row.finishedDealsProfitUsd / maxCurrencyUsed) * 100;
          }

          const isDollarCoinOrPercent =
            !!dollarCoins.includes(currencyBaseTypeString) ||
            row.baseOrderVolumeType === "percent";

          // Calculate baggedness
          let isBaggedOnAtLeastOnePair = false;
          row.activeDeals.forEach((activeDeal) => {
            isBaggedOnAtLeastOnePair =
              activeDeal.completedSafetyOrdersCounts ===
              activeDeal.maxSafetyOrders;
          });

          // Render table
          return (
            <Popup
              key={`BotsTable-Popup-${index}`}
              trigger={
                <Table.Row
                  key={`botRow${index}`}
                  active={index === activeIndex}
                  onClick={() => setActiveIndex(index)}
                >
                  <Table.Cell collapsing>{index + 1}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      icon
                      style={{
                        backgroundColor: !row.isMarkedForComparison && "white",
                      }}
                      color={
                        row.isMarkedForComparison ? "instagram" : undefined
                      }
                      onClick={() =>
                        row.addToComparison(row.botId.toString(), row.userName)
                      }
                    >
                      <Icon name="lab" />
                    </Button>
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Label tag color="grey">
                      {row.userName}
                    </Label>
                  </Table.Cell>
                  <Table.Cell collapsing>{row.botName}</Table.Cell>
                  <Table.Cell collapsing>
                    <Icon
                      name={
                        row.isEnabled
                          ? "check square outline"
                          : "square outline"
                      }
                      color={row.isEnabled ? "green" : "yellow"}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {isBaggedOnAtLeastOnePair ? (
                      <Icon name="shopping bag" color="orange" />
                    ) : (
                      "-"
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>{`${
                    daysAgoCreation === 0 ? "today" : daysAgoCreation
                  } ${
                    daysAgoCreation === 0
                      ? ""
                      : daysAgoCreation > 1
                      ? "days ago"
                      : "day ago"
                  }`}</Table.Cell>
                  <Table.Cell
                    collapsing
                    style={{
                      color:
                        row.finishedDealsProfitUsd > 0
                          ? "green"
                          : row.finishedDealsCount === 0
                          ? "black"
                          : "red",
                    }}
                  >
                    ${Number(row.finishedDealsProfitUsd).toFixed(2)}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    style={{
                      color: !isDollarCoinOrPercent
                        ? "black"
                        : totalPercentProfit > 0
                        ? "green"
                        : row.finishedDealsCount === 0
                        ? "black"
                        : "red",
                    }}
                  >
                    {isDollarCoinOrPercent ? (
                      `${Number(totalPercentProfit).toFixed(2)}%`
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Icon name="cancel" style={{ color: "lightgray" }} />
                        <p
                          style={{ fontSize: "0.8rem" }}
                        >{`Requires dollar coin`}</p>
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {row.finishedDealsCount
                      ? `$
                  ${(
                    row.finishedDealsProfitUsd / row.finishedDealsCount
                  ).toFixed(2)}`
                      : "-"}
                  </Table.Cell>
                  <Table.Cell collapsing>{row.finishedDealsCount}</Table.Cell>
                  <Table.Cell collapsing>
                    {row.finishedDealsCount === 0
                      ? "-"
                      : avgDealsTimeInMinutes > 60
                      ? `${(avgDealsTimeInMinutes / 60).toFixed(1)} hr`
                      : `${avgDealsTimeInMinutes.toFixed(1)} min`}
                  </Table.Cell>
                  <Table.Cell warning collapsing>
                    {`${maxCurrencyUsed.toFixed(2)} `}
                    <Label size="tiny">{currencyBaseTypeString}</Label>
                  </Table.Cell>
                  <Table.Cell
                    warning
                    collapsing
                  >{`${maxDeviationCovered.toFixed(2)}%`}</Table.Cell>
                  <Table.Cell collapsing>{row.strategy}</Table.Cell>
                  <Table.Cell collapsing>
                    {row.pairs.length === 1 ? (
                      row.pairs
                    ) : (
                      <div>
                        <Icon name="connectdevelop" color="blue" />
                        {row.pairs[0]} + {row.pairs.length - 1}
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon style={{ color: "lightgray" }} name="circle" />
                    {`${row.baseOrderVolume} `}
                    <Label size="tiny">{currencyBaseTypeString}</Label>
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon style={{ color: "lightgray" }} name="circle notch" />
                    {`${row.safetyOrderVolume} `}
                    <Label size="tiny">{currencyBaseTypeString}</Label>
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {row.strategyList?.map((startCondition, index) => {
                      let conditionStringToRender = startCondition.strategy;
                      if (startCondition.options.time) {
                        conditionStringToRender += ` ${startCondition.options.time}`;
                      }
                      if (startCondition.options.type) {
                        conditionStringToRender += ` ${startCondition.options.type}`;
                      }
                      if (startCondition.options.points) {
                        conditionStringToRender += ` ${startCondition.options.points}`;
                      }
                      return (
                        <Label key={`stratRow${index}`} circular>
                          {conditionStringToRender}
                        </Label>
                      );
                    })}
                  </Table.Cell>
                  <Table.Cell collapsing>{row.maxActiveDeals}</Table.Cell>
                  <Table.Cell collapsing>
                    <Icon style={{ color: "lightgray" }} name="target" />
                    {row.takeProfit}% / {row.takeProfitType}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon style={{ color: "lightgray" }} name="line graph" />
                    {row.trailingDeviation}%
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon style={{ color: "lightgray" }} name="maxcdn" />
                    {row.maxSafetyOrders}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon style={{ color: "lightgray" }} name="angle down" />
                    {row.safetyOrderStepPercentage}%
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon
                      style={{ color: "lightgray" }}
                      name="angle double up"
                    />
                    {row.martingaleVolumeCoefficient}x
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon
                      style={{ color: "lightgray" }}
                      name="angle double down"
                    />
                    {row.martingaleStepCoefficient}x
                  </Table.Cell>
                </Table.Row>
              }
            >
              <Popup.Header>{`Active Deals for ${row.botName}`}</Popup.Header>
              <Popup.Content>
                <List divided relaxed>
                  {row.activeDeals.map((activeDeal) => {
                    let targetDirectionString: string = "";
                    let neededPercentageForTakeProfitString: string = "";
                    if (row.strategy === "long") {
                      targetDirectionString = "Gain needed";
                      const neededPercentageForTakeProfit =
                        (Number(activeDeal.takeProfitPrice) -
                          Number(activeDeal.currentPrice)) /
                        Number(activeDeal.currentPrice);
                      neededPercentageForTakeProfitString = `${(
                        neededPercentageForTakeProfit * 100
                      ).toFixed(2)}%`;
                    } else {
                      targetDirectionString = "Dip needed";
                      const neededPercentageForTakeProfit =
                        (Number(activeDeal.currentPrice) -
                          Number(activeDeal.takeProfitPrice)) /
                        Number(activeDeal.currentPrice);
                      neededPercentageForTakeProfitString = `-${(
                        neededPercentageForTakeProfit * 100
                      ).toFixed(2)}%`;
                    }
                    return (
                      <List.Item
                        key={`BotsTable-activeDeal-${index}-${activeDeal.boughtAveragePrice}`}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Label>
                            {`${activeDeal.pair} | ${row.strategy}`}
                            <Label.Detail>
                              {moment(activeDeal.createdAt).from(new Date())}
                            </Label.Detail>
                          </Label>
                          <Label color="teal">
                            {`Safeties`}
                            <Label.Detail>{`${activeDeal.completedSafetyOrdersCounts} / ${activeDeal.maxSafetyOrders}`}</Label.Detail>
                          </Label>
                          <Label color="green">
                            <Icon name="target" />
                            {targetDirectionString}
                            <Label.Detail>
                              {neededPercentageForTakeProfitString}
                            </Label.Detail>
                          </Label>
                        </div>
                      </List.Item>
                    );
                  })}
                  {row.activeDeals.length === 0 && (
                    <Label color="black">No active deals</Label>
                  )}
                </List>
              </Popup.Content>
            </Popup>
          );
        })}
      </Table.Body>
    </Table>
  );
};
