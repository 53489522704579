import React from "react";
import { Grid } from "semantic-ui-react";
import { IUserBotsAndActiveDealsInfo } from "../types";
import { LeaderboardList } from "./LeaderboardList";
import { LeaderboardSummary } from "./LeaderboardSummary";

interface ILeaderboardWrapperProps {
  usersBotsDealsInfo: IUserBotsAndActiveDealsInfo[];
}

export const LeaderboardWrapper: React.FunctionComponent<ILeaderboardWrapperProps> =
  (props) => {
    const { usersBotsDealsInfo } = props;
    let combinedMonthlyUsdProfit: number = 0;
    let combinedDailyUsdProit: number = 0;
    let monthlyLeaderboardMetrics: {
      name: string;
      percentageGain: number;
    }[] = [];
    let dailyLeaderboardMetrics: { name: string; percentageGain: number }[] =
      [];

    usersBotsDealsInfo.forEach((userTotalData) => {
      const accountInfo = userTotalData.accountInfo;
      const userMonthlyProfit = accountInfo.usd_profit;
      const userDailyProfit = accountInfo.day_profit_usd;

      // Add up combined overall statistics
      combinedMonthlyUsdProfit += Number(userMonthlyProfit);
      combinedDailyUsdProit += Number(userDailyProfit);

      // Add percentage gains for sorting
      monthlyLeaderboardMetrics.push({
        name: userTotalData.userInfo.name,
        percentageGain: Number(accountInfo.usd_profit_percentage),
      });
      dailyLeaderboardMetrics.push({
        name: userTotalData.userInfo.name,
        percentageGain: Number(accountInfo.day_profit_usd_percentage),
      });
    });

    // Sort percentage gains for leaderboards
    monthlyLeaderboardMetrics = monthlyLeaderboardMetrics.sort((a, b) =>
      a.percentageGain < b.percentageGain ? 1 : -1
    );
    dailyLeaderboardMetrics = dailyLeaderboardMetrics.sort((a, b) =>
      a.percentageGain < b.percentageGain ? 1 : -1
    );

    // Convert leaderboard metrics for pretty consumption
    const monthlyLeaderboardMetricsPretty: { name: string; metric: string }[] =
      monthlyLeaderboardMetrics.map((item) => {
        return {
          name: item.name,
          metric: `${item.percentageGain.toFixed(2)}%`,
        };
      });
    const dailyLeaderboardMetricsPretty: { name: string; metric: string }[] =
      dailyLeaderboardMetrics.map((item) => {
        return {
          name: item.name,
          metric: `${item.percentageGain.toFixed(2)}%`,
        };
      });

    return (
      <Grid columns={3} celled="internally" stackable>
        <Grid.Row>
          <Grid.Column>
            <LeaderboardSummary
              titleTop={"Overall"}
              titleBottom={"Statistics"}
              stepContent={[
                {
                  iconName: "dollar sign",
                  iconColor: combinedMonthlyUsdProfit > 0 ? "green" : "red",
                  title: `$${combinedMonthlyUsdProfit.toFixed(2)}`,
                  subtitle: "Monthly profit by everyone combined",
                },
                {
                  iconName: "dollar sign",
                  iconColor: combinedDailyUsdProit > 0 ? "green" : "red",
                  title: `$${combinedDailyUsdProit.toFixed(2)}`,
                  subtitle: "Daily profit by everyone combined",
                },
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <LeaderboardList
              titleTop={"Daily"}
              titleBottom={"PNL"}
              orderedRows={dailyLeaderboardMetricsPretty}
            />
          </Grid.Column>
          <Grid.Column>
            <LeaderboardList
              titleTop={"Monthly"}
              titleBottom={"PNL"}
              orderedRows={monthlyLeaderboardMetricsPretty}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
