import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  Icon,
  Input,
  InputOnChangeData,
  Label,
  Modal,
  Table,
} from "semantic-ui-react";
import { IBotInfo, IDealInfo } from "../types";
import {
  calculateMaxCurrencyUsed,
  deriveGradientColor,
  fillInDateRangeEmpties,
} from "../utils";
import { rbgColors } from "../constants";

interface IBotsComparisonModalProps {
  open: boolean;
  onClose: () => void;
  botsAndDealsData: { botInfo: IBotInfo; dealsInfo: IDealInfo[] }[];
}

interface IDayDealData {
  actualUsdProfit: number;
  percentProfit: number;
  relativeProfictScale: number; // High profit day in history for this bot = 1, lowest = 0
  numOfDeals: number;
  safetiesReached: number;
  coinPairsData: {
    [coinPair: string]: ICoinPairData;
  };
  sortedCoinPairsData?: ISortedCoinPairData[];
}

interface ICoinPairData {
  actualUsdProfit: number;
  percentProfit: number;
  numOfDeals: number;
  safetiesReached: number;
  lengthOfDeals: number; //seconds
}

interface ISortedCoinPairData extends ICoinPairData {
  avgSafeties: number; // Need to calculate for sorting
  avgLengthOfDeals: number; // Need to calculate for sorting
  coinPair: string;
  weights: ICoinPairWeights;
}

interface ICoinPairWeights {
  finalWeightRanking: number;
  percentProfitWeight: number;
  numOfDealsWeight: number;
  avgSafetiesReachedWeight: number;
  lengthOfDealWeight: number;
}

const weightDefaults = {
  percentProfitWeightImportance: 3,
  numOfDealsWeightImportance: 1,
  avgSafetiesReachedWeightImportance: 0,
  lengthOfDealWeightImportance: 1,
};

const enableTopEarnerForDayStar = true;

export const BotsComparisonModal: React.FunctionComponent<
  IBotsComparisonModalProps
> = (props) => {
  const { open, onClose, botsAndDealsData } = props;
  const [isShowingCoinPairsData, setIsShowingCoinPairsData] =
    useState<boolean>(false);
  // Weight defaults
  const [percentProfitWeightImportance, setPercentProfitWeightMultipler] =
    useState<number>(weightDefaults.percentProfitWeightImportance);
  const [numOfDealsWeightImportance, setNumOfDealsWeightImportance] =
    useState<number>(weightDefaults.numOfDealsWeightImportance);
  const [
    avgSafetiesReachedWeightImportance,
    setAvgSafetiesReachedWeightImportance,
  ] = useState<number>(weightDefaults.avgSafetiesReachedWeightImportance);
  const [lengthOfDealWeightImportance, setLengthOfDealWeightImportance] =
    useState<number>(weightDefaults.lengthOfDealWeightImportance);
  const [allWeights, setAllWeights] = useState<{
    percentProfitWeightImportance: number;
    numOfDealsWeightImportance: number;
    avgSafetiesReachedWeightImportance: number;
    lengthOfDealWeightImportance: number;
  }>({
    percentProfitWeightImportance: weightDefaults.percentProfitWeightImportance,
    numOfDealsWeightImportance: weightDefaults.numOfDealsWeightImportance,
    avgSafetiesReachedWeightImportance:
      weightDefaults.avgSafetiesReachedWeightImportance,
    lengthOfDealWeightImportance: weightDefaults.lengthOfDealWeightImportance,
  });

  const onApplyNewWeights = () => {
    setAllWeights({
      percentProfitWeightImportance,
      numOfDealsWeightImportance,
      avgSafetiesReachedWeightImportance,
      lengthOfDealWeightImportance,
    });
  };

  const onPercentProfitWeightImportanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setPercentProfitWeightMultipler(Number(data.value));
  };

  const onNumOfDealsWeightImportanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setNumOfDealsWeightImportance(Number(data.value));
  };

  const onAvgSafetiesReachedWeightImportanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setAvgSafetiesReachedWeightImportance(Number(data.value));
  };

  const onLengthOfDealWeightImportanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setLengthOfDealWeightImportance(Number(data.value));
  };

  let allDaysAvailable: string[] = [];
  const dealsDataPerDay: {
    [botId: string]: { [day: string]: IDayDealData };
  } = {};
  botsAndDealsData.forEach((botAndDeals) => {
    const dayDealData: { [day: string]: IDayDealData } = {};
    const maxCurrencyUsed = calculateMaxCurrencyUsed(botAndDeals.botInfo);
    botAndDeals.dealsInfo.forEach((deal) => {
      // Grab all unique closing days
      const closingDay = moment(deal.closed_at).format("MM/DD/YYYY");
      if (!allDaysAvailable.includes(closingDay)) {
        allDaysAvailable.push(closingDay);
      }

      // Calculate needed cell data
      const additionalActualUsdProfit = Number(deal.actual_usd_profit);
      const additionalPercentProfit =
        (Number(deal.actual_usd_profit) / maxCurrencyUsed.amount) * 100;
      const additionalSafetiesReached = Number(
        deal.completed_safety_orders_count
      );
      const additionalLengthOfDeals = moment(deal.closed_at).diff(
        deal.created_at,
        "seconds"
      );
      if (dayDealData[closingDay] === undefined) {
        dayDealData[closingDay] = {
          actualUsdProfit: additionalActualUsdProfit,
          percentProfit: additionalPercentProfit,
          relativeProfictScale: 1,
          numOfDeals: 1,
          safetiesReached: additionalSafetiesReached,
          coinPairsData: {
            [deal.pair]: {
              actualUsdProfit: additionalActualUsdProfit,
              percentProfit: additionalPercentProfit,
              numOfDeals: 1,
              safetiesReached: additionalSafetiesReached,
              lengthOfDeals: additionalLengthOfDeals,
            },
          },
        };
      } else {
        dayDealData[closingDay].actualUsdProfit += additionalActualUsdProfit;
        dayDealData[closingDay].percentProfit += additionalPercentProfit;
        dayDealData[closingDay].numOfDeals += 1;
        dayDealData[closingDay].safetiesReached += additionalSafetiesReached;
        if (dayDealData[closingDay].coinPairsData[deal.pair]) {
          dayDealData[closingDay].coinPairsData[deal.pair].actualUsdProfit +=
            additionalActualUsdProfit;
          dayDealData[closingDay].coinPairsData[deal.pair].percentProfit +=
            additionalPercentProfit;
          dayDealData[closingDay].coinPairsData[deal.pair].numOfDeals += 1;
          dayDealData[closingDay].coinPairsData[deal.pair].safetiesReached +=
            additionalSafetiesReached;
          dayDealData[closingDay].coinPairsData[deal.pair].lengthOfDeals +=
            additionalLengthOfDeals;
        } else {
          dayDealData[closingDay].coinPairsData[deal.pair] = {
            actualUsdProfit: additionalActualUsdProfit,
            percentProfit: additionalPercentProfit,
            numOfDeals: 1,
            safetiesReached: additionalSafetiesReached,
            lengthOfDeals: additionalLengthOfDeals,
          };
        }
      }
    });

    // Add all calculated data for specific bot into dict
    dealsDataPerDay[botAndDeals.botInfo.id] = dayDealData;
  });

  Object.keys(dealsDataPerDay).forEach((botId) => {
    let highestEverActualProfit = 0;
    Object.keys(dealsDataPerDay[botId]).forEach((dayDate) => {
      const dayActualUsdProfit =
        dealsDataPerDay[botId][dayDate].actualUsdProfit;
      if (dayActualUsdProfit > highestEverActualProfit) {
        highestEverActualProfit = dayActualUsdProfit;
      }
    });
    Object.keys(dealsDataPerDay[botId]).forEach((dayDate) => {
      const dayActualUsdProfit =
        dealsDataPerDay[botId][dayDate].actualUsdProfit;
      dealsDataPerDay[botId][dayDate].relativeProfictScale =
        dayActualUsdProfit / highestEverActualProfit;
    });
  });

  // TODO - time length of deal
  // TODO - # of times bagged
  const renderWeightInput = (
    value: number,
    onChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      data: InputOnChangeData
    ) => void,
    title: string
  ): JSX.Element => {
    return (
      <Input
        label={title}
        placeholder={title}
        value={value}
        onChange={onChange}
        style={{ marginBottom: "0.5rem" }}
        size="mini"
      />
    );
  };

  // Sort days, most recent on left
  allDaysAvailable.sort((a, b) => moment.utc(a).diff(moment.utc(b))).reverse();

  // Fill in gaps between days with no deals
  allDaysAvailable = fillInDateRangeEmpties(
    allDaysAvailable[0],
    allDaysAvailable[allDaysAvailable.length - 1]
  );

  // Apply coin pair weight rankings
  Object.keys(dealsDataPerDay).forEach((botId) => {
    Object.keys(dealsDataPerDay[botId]).forEach((day) => {
      const dayData = dealsDataPerDay[botId][day];
      // Calculate coin pair data and sort them
      let sortedCoinPairsData: ISortedCoinPairData[] = [];
      Object.keys(dayData?.coinPairsData || {}).forEach((coinPair) => {
        const coinPairData =
          dealsDataPerDay[botId][day].coinPairsData[coinPair];
        sortedCoinPairsData.push({
          ...coinPairData,
          coinPair,
          avgSafeties: coinPairData.safetiesReached / coinPairData.numOfDeals,
          avgLengthOfDeals:
            coinPairData.lengthOfDeals / coinPairData.numOfDeals,
          weights: {
            finalWeightRanking: 0,
            percentProfitWeight: 0,
            numOfDealsWeight: 0,
            avgSafetiesReachedWeight: 0,
            lengthOfDealWeight: 0,
          },
        });
      });

      // Sort by percentage gain, highest best
      sortedCoinPairsData.sort((a, b) =>
        a.percentProfit < b.percentProfit ? 1 : -1
      );
      sortedCoinPairsData.forEach((pairData, index) => {
        pairData.weights.percentProfitWeight = index + 1;
      });

      // Sort by numOfDeals, highest best
      sortedCoinPairsData.sort((a, b) =>
        a.numOfDeals < b.numOfDeals ? 1 : -1
      );
      sortedCoinPairsData.forEach((pairData, index) => {
        pairData.weights.numOfDealsWeight = index + 1;
      });

      // Sort by avgSafeties, highest best
      sortedCoinPairsData.sort((a, b) =>
        a.avgSafeties < b.avgSafeties ? 1 : -1
      );
      sortedCoinPairsData.forEach((pairData, index) => {
        pairData.weights.avgSafetiesReachedWeight = index + 1;
      });

      // Sort by avg length of deals, lowest best
      sortedCoinPairsData.sort((a, b) =>
        a.avgLengthOfDeals > b.avgLengthOfDeals ? 1 : -1
      );
      sortedCoinPairsData.forEach((pairData, index) => {
        pairData.weights.lengthOfDealWeight = index + 1;
      });

      // Calculate final weight ranking, lowest number is best
      sortedCoinPairsData.forEach((pairData, index) => {
        const {
          percentProfitWeight,
          numOfDealsWeight,
          avgSafetiesReachedWeight,
          lengthOfDealWeight,
        } = pairData.weights;

        const finalWeightRanking =
          (percentProfitWeight * allWeights.percentProfitWeightImportance +
            numOfDealsWeight * allWeights.numOfDealsWeightImportance +
            avgSafetiesReachedWeight *
              allWeights.avgSafetiesReachedWeightImportance +
            lengthOfDealWeight * allWeights.lengthOfDealWeightImportance) /
          (percentProfitWeightImportance +
            numOfDealsWeightImportance +
            avgSafetiesReachedWeightImportance +
            lengthOfDealWeightImportance);

        pairData.weights.finalWeightRanking = finalWeightRanking;
      });

      // Sort by final weight ranking
      sortedCoinPairsData.sort((a, b) =>
        a.weights.finalWeightRanking > b.weights.finalWeightRanking ? 1 : -1
      );

      dealsDataPerDay[botId][day].sortedCoinPairsData = sortedCoinPairsData;
    });
  });

  return (
    <Modal open={open} onClose={onClose} size="fullscreen" closeIcon>
      <Modal.Header>Bot Analysis - Past 1,000 Deals per Bot</Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {renderWeightInput(
            percentProfitWeightImportance,
            onPercentProfitWeightImportanceChange,
            "Percent Profit"
          )}
          {renderWeightInput(
            numOfDealsWeightImportance,
            onNumOfDealsWeightImportanceChange,
            "Num of Deals"
          )}
          {renderWeightInput(
            avgSafetiesReachedWeightImportance,
            onAvgSafetiesReachedWeightImportanceChange,
            "Avg Safeties"
          )}
          {renderWeightInput(
            lengthOfDealWeightImportance,
            onLengthOfDealWeightImportanceChange,
            "Avg Length of Deals"
          )}
          <Button
            color="instagram"
            onClick={onApplyNewWeights}
            style={{ marginBottom: "0.5rem" }}
          >
            {"Apply weights"}
          </Button>
          <Button
            color="instagram"
            icon
            labelPosition="right"
            style={{ marginBottom: "0.5rem" }}
            onClick={() => {
              setIsShowingCoinPairsData(!isShowingCoinPairsData);
            }}
          >
            <Icon name={isShowingCoinPairsData ? "eye" : "eye slash"} />
            {isShowingCoinPairsData ? "Hide coins data" : "Show coins data"}
          </Button>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <Table celled compact="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{`BotName`}</Table.HeaderCell>
                {allDaysAvailable.map((dayAvailable) => {
                  return (
                    <Table.HeaderCell
                      key={`comparison-dayavailable-${dayAvailable}`}
                    >
                      {dayAvailable}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {botsAndDealsData.map((botAndDeals) => {
                const botId = botAndDeals.botInfo.id;
                const isPercentBaseCurrencyType =
                  botAndDeals.botInfo.base_order_volume_type === "percent";
                const daysCells = allDaysAvailable.map((day) => {
                  const dayData = dealsDataPerDay[botId][day];
                  if (dayData) {
                    let isTopPercentEarnerForDay = true;
                    botsAndDealsData.forEach((otherBotAndDeals) => {
                      const otherBotId = otherBotAndDeals.botInfo.id;
                      const otherDayData = dealsDataPerDay[otherBotId][day];
                      if (otherDayData) {
                        if (
                          dayData.percentProfit < otherDayData.percentProfit
                        ) {
                          isTopPercentEarnerForDay = false;
                        }
                      }
                    });
                    return (
                      <Table.Cell
                        key={`comparison-dayDataCell-${botId}-${day}`}
                        textAlign="left"
                        collapsing
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Label
                            style={{
                              backgroundColor: deriveGradientColor(
                                dayData.relativeProfictScale,
                                rbgColors.offWhite,
                                rbgColors.green
                              ),
                            }}
                          >
                            {isPercentBaseCurrencyType
                              ? "?"
                              : dayData.percentProfit.toFixed(2)}
                            %
                            {enableTopEarnerForDayStar && (
                              <Label.Detail>
                                {isTopPercentEarnerForDay && (
                                  <Icon name="star" color="yellow" />
                                )}
                              </Label.Detail>
                            )}
                          </Label>
                          <span
                            style={{
                              color:
                                dayData.actualUsdProfit < 0 ? "red" : undefined,
                            }}
                          >
                            <strong>
                              ${dayData.actualUsdProfit.toFixed(2)}
                            </strong>
                          </span>
                          <span>{`Deals: ${dayData.numOfDeals}`}</span>
                          <Label
                            style={{
                              backgroundColor: deriveGradientColor(
                                dayData.safetiesReached /
                                  dayData.numOfDeals /
                                  botAndDeals.botInfo.max_safety_orders,
                                rbgColors.white,
                                rbgColors.yellow
                              ),
                            }}
                          >
                            <span style={{ fontSize: "0.7rem" }}>
                              Avg Safeties
                            </span>
                            <br />
                            <span>{`${(
                              dayData.safetiesReached / dayData.numOfDeals
                            ).toFixed(1)} / ${
                              botAndDeals.botInfo.max_safety_orders
                            }`}</span>
                          </Label>
                          {isShowingCoinPairsData &&
                            !!dayData.sortedCoinPairsData &&
                            dayData.sortedCoinPairsData.map(
                              (coinPairDayData, index) => {
                                return (
                                  <div
                                    key={`botsComparisonModal-coinPair-${botId}=${coinPairDayData.coinPair}`}
                                  >
                                    <Label circular>{index + 1}</Label>
                                    <Label>
                                      {coinPairDayData.coinPair}
                                      <Label.Detail>{`${(
                                        (coinPairDayData.percentProfit /
                                          dayData.percentProfit) *
                                        100
                                      ).toFixed(2)}%`}</Label.Detail>
                                      <Label.Detail>{`Deals: ${coinPairDayData.numOfDeals}`}</Label.Detail>
                                      <Label.Detail>{`Avg Safeties: ${coinPairDayData.avgSafeties.toFixed(
                                        1
                                      )}`}</Label.Detail>
                                      <Label.Detail>{`Avg Deal Time: ${(
                                        coinPairDayData.avgLengthOfDeals / 60
                                      ).toFixed(0)}m`}</Label.Detail>
                                    </Label>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </Table.Cell>
                    );
                  } else {
                    return (
                      <Table.Cell
                        key={`comparison-dayDataCell-${botId}-${day}`}
                      >
                        -
                      </Table.Cell>
                    );
                  }
                });
                return (
                  <Table.Row key={`comparison-dayDataRowName-${botId}}`}>
                    <Table.Cell>{botAndDeals.botInfo.name}</Table.Cell>
                    {daysCells}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </Modal.Content>
    </Modal>
  );
};
