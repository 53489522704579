import React, { useState } from "react";
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Icon,
  Input,
  InputOnChangeData,
  Label,
} from "semantic-ui-react";
import {
  IBotInfo,
  IDealInfo,
  IDealsForBots,
  IUserBotsAndActiveDealsInfo,
} from "../types";
import { BotsTable, IBotsTableRow } from "./BotsTable";
import { BotsComparisonModal } from "./BotsComparisonModal";
import { getLocalStorageValue, setLocalStorageValue } from "../utils";
import { LocalStorageKey } from "../constants";

const enableDisabledBotsButton = true;
const enableBotNameQueryInput = true;

interface IBotsTableWrapperProps {
  usersBotsDealsInfo: IUserBotsAndActiveDealsInfo[];
  botsClosedDealsData: IDealsForBots;
  fetchBotsClosedDealsData: (
    botsToFetch: { userName: string; botId: string }[],
    callback: () => void
  ) => void;
}

export const BotsTableWrapper: React.FunctionComponent<
  IBotsTableWrapperProps
> = (props) => {
  const { usersBotsDealsInfo, botsClosedDealsData, fetchBotsClosedDealsData } =
    props;
  const [botsToCompare, setBotsToCompare] = useState<{
    [botId: string]: { userName: string; botId: string };
  }>({});
  const [isLoadingComparisonData, setIsLoadingComparisonData] =
    useState<boolean>(false);
  const [showDisabledBots, setShowDisabledBots] = useState<boolean>(
    getLocalStorageValue(LocalStorageKey.ShowDisabledBots) === "true" || false
  );
  const [selectedUserName, setSelectedUserName] = useState<string>(
    getLocalStorageValue(LocalStorageKey.SelectedUserName) || ""
  );
  const [selectedCoinPair, setSelectedCoinPair] = useState<string>(
    getLocalStorageValue(LocalStorageKey.SelectedCoinPair) || ""
  );
  const [botNameQuery, setBotNameQuery] = useState<string>(
    getLocalStorageValue(LocalStorageKey.BotNameQuery) || ""
  );
  const [isComparisonModalOpen, setIsComparisonModalOpen] =
    useState<boolean>(false);

  const openComparisonModal = () => {
    setIsComparisonModalOpen(true);
  };

  const closeComparisonModal = () => {
    setIsComparisonModalOpen(false);
  };

  const setBotNameQueryWithLocalStorage = (newBotNameQuery: string) => {
    setLocalStorageValue(LocalStorageKey.BotNameQuery, newBotNameQuery);
    setBotNameQuery(newBotNameQuery);
  };

  const onBotNameQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setBotNameQueryWithLocalStorage(data.value);
  };

  const addToComparison = (botId: string, userName: string) => {
    let newBotsToCompare: {
      [botId: string]: { userName: string; botId: string };
    };
    if (!!botsToCompare[botId]) {
      newBotsToCompare = _.cloneDeep(botsToCompare);
      delete newBotsToCompare[botId];
    } else {
      newBotsToCompare = {
        ...botsToCompare,
        [botId]: { userName, botId },
      };
    }
    setBotsToCompare(newBotsToCompare);
  };

  const botsToCompareLength = Object.keys(botsToCompare).length;

  const clearBotsToCompare = () => {
    setBotsToCompare({});
  };

  const beginFetchingClosedDealsAndOpenModal = () => {
    if (botsToCompareLength === 0) {
      alert("No bots selected");
      return;
    } else if (botsToCompareLength > 5) {
      alert("5 bot limit for comparison");
      return;
    }
    setIsLoadingComparisonData(true);
    fetchBotsClosedDealsData(Object.values(botsToCompare), () => {
      setIsLoadingComparisonData(false);
      openComparisonModal();
    });
  };

  const botsAndDealsData: { botInfo: IBotInfo; dealsInfo: IDealInfo[] }[] = [];
  usersBotsDealsInfo.forEach((userTotalData) => {
    userTotalData.botsInfo.forEach((botInfo) => {
      if (botsToCompare[botInfo.id]) {
        botsAndDealsData.push({
          botInfo,
          dealsInfo: botsClosedDealsData[botInfo.id] || [],
        });
      }
    });
  });

  // Table Rows
  const rows: IBotsTableRow[] = [];
  const userNameOptions: DropdownItemProps[] = [];
  const coinPairOptions: DropdownItemProps[] = [];

  usersBotsDealsInfo.forEach((userTotalData) => {
    const userName = userTotalData.userInfo.name;
    userNameOptions.push({
      text: userName,
      value: userName,
    });
    if (!selectedUserName || selectedUserName === userName) {
      userTotalData.botsInfo.forEach((bot) => {
        bot.pairs?.forEach((pair) => {
          coinPairOptions.push({
            text: pair,
            value: pair,
          });
        });
        const matchesBotNameQuery = botNameQuery
          ? !!bot.name.toLowerCase().includes(botNameQuery.toLowerCase())
          : true;
        const matchesCoinPairQuery = selectedCoinPair
          ? bot.pairs?.includes(selectedCoinPair)
          : true;
        if (
          matchesBotNameQuery &&
          matchesCoinPairQuery &&
          (bot.is_enabled || showDisabledBots)
        ) {
          const activeDealsForBot = userTotalData.activeDealsInfo.filter(
            (dealInfo: any) => dealInfo.bot_id === bot.id
          );
          rows.push({
            addToComparison,
            isMarkedForComparison: !!botsToCompare[bot.id],
            userName,
            accountUsdAmount: Number(userTotalData.accountInfo.usd_amount),
            botName: bot.name,
            botId: bot.id,
            createdAt: bot.created_at,
            isEnabled: bot.is_enabled,
            botType: bot.type,
            finishedDealsProfitUsd: Number(bot.finished_deals_profit_usd),
            finishedDealsCount: Math.round(Number(bot.finished_deals_count)),
            pairs: bot.pairs || [], // BTC_ETH
            strategy: bot.strategy, // long/short
            baseOrderVolume: Number(bot.base_order_volume),
            baseOrderVolumeType: bot.base_order_volume_type, // maybe add %
            safetyOrderVolume: Number(bot.safety_order_volume),
            safetyOrderVolumeType: bot.safety_order_volume_type, // maybe add %
            strategyList: bot.strategy_list, //start conditions
            takeProfit: Number(bot.take_profit), // %
            takeProfitType: bot.take_profit_type,
            trailingDeviation: Number(bot.trailing_deviation), // %
            maxSafetyOrders: bot.max_safety_orders,
            safetyOrderStepPercentage: Number(bot.safety_order_step_percentage), // %
            martingaleVolumeCoefficient: Number(
              bot.martingale_volume_coefficient
            ), // %
            martingaleStepCoefficient: Number(bot.martingale_step_coefficient), // %
            maxActiveDeals: bot.max_active_deals,
            activeDeals: activeDealsForBot.map((dealInfoForBot) => {
              return {
                createdAt: dealInfoForBot.created_at,
                status: dealInfoForBot.status,
                boughtAveragePrice: dealInfoForBot.bought_average_price,
                soldAveragePrice: dealInfoForBot.sold_average_price,
                currentPrice: dealInfoForBot.current_price,
                takeProfitPrice: dealInfoForBot.take_profit_price,
                maxSafetyOrders: dealInfoForBot.max_safety_orders,
                completedSafetyOrdersCounts:
                  dealInfoForBot.completed_safety_orders_count,
                pair: dealInfoForBot.pair,
              };
            }),
          });
        }
      });
    }
  });

  const onShowDisabledBotsClick = () => {
    const newShowingDisabledBots = !showDisabledBots;
    setLocalStorageValue(
      LocalStorageKey.ShowDisabledBots,
      `${newShowingDisabledBots}`
    );
    setShowDisabledBots(newShowingDisabledBots);
  };

  const onSelectUserNameChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    const newSelectedUserName = data.value as string;
    setLocalStorageValue(LocalStorageKey.SelectedUserName, newSelectedUserName);
    setSelectedUserName(newSelectedUserName);
  };

  const onSelectCoinPairChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    const newSelectedCoinPair = data.value as string;
    setLocalStorageValue(LocalStorageKey.SelectedCoinPair, newSelectedCoinPair);
    setSelectedCoinPair(newSelectedCoinPair);
  };

  // Dedup coin pairs
  const dedupedCoinPairOptions = _.uniqBy(coinPairOptions, "value");

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {enableDisabledBotsButton && (
            <Button onClick={onShowDisabledBotsClick} size="medium">
              <Icon name={showDisabledBots ? "checkmark box" : "square"} />
              <Label as="a" basic pointing="left">
                {showDisabledBots ? "Hide Disabled Bots" : "Show Disabled Bots"}
              </Label>
            </Button>
          )}
          <Button size="tiny">
            <Dropdown
              clearable
              options={userNameOptions}
              selection
              search
              value={selectedUserName}
              onChange={onSelectUserNameChange}
              placeholder={"Filter by Owner"}
            />
          </Button>
          <Button size="tiny">
            <Dropdown
              clearable
              options={dedupedCoinPairOptions}
              selection
              search
              value={selectedCoinPair}
              onChange={onSelectCoinPairChange}
              placeholder={"Filter by Coin Pair"}
            />
          </Button>
          {enableBotNameQueryInput && (
            <Button size="tiny">
              <Input
                placeholder="Filter by BotName"
                action={{
                  icon: "cancel",
                  onClick: () => setBotNameQueryWithLocalStorage(""),
                }}
                value={botNameQuery}
                onChange={onBotNameQueryChange}
              />
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Button
            color="instagram"
            disabled={isLoadingComparisonData}
            loading={isLoadingComparisonData}
            onClick={beginFetchingClosedDealsAndOpenModal}
          >{`Compare Bots (${botsToCompareLength})`}</Button>
          <Button
            animated="fade"
            color="instagram"
            disabled={isLoadingComparisonData}
            onClick={clearBotsToCompare}
          >
            <Button.Content hidden>Clear</Button.Content>
            <Button.Content visible>
              <Icon name="cancel" />
            </Button.Content>
          </Button>
        </div>
      </div>
      <div style={{ overflowX: "scroll" }}>
        <BotsTable rows={rows} />
      </div>
      <BotsComparisonModal
        open={isComparisonModalOpen}
        onClose={closeComparisonModal}
        botsAndDealsData={botsAndDealsData}
      />
    </div>
  );
};
