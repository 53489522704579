export const dollarCoins = [
  "BUSD",
  "USDT",
  "UST",
  "USDC",
  "USDN",
  "TUSD",
  "PAX",
  "CUSD",
  "USDEX",
  "USDQ",
];
export enum LocalStorageKey {
  SelectedUserName = "SelectedUserName",
  ShowDisabledBots = "ShowDisabledBots",
  SelectedCoinPair = "SelectedCoinPair",
  BotNameQuery = "BotNameQuery",
}
export const rbgColors: {
  [colorName: string]: [r: number, b: number, g: number];
} = {
  white: [255, 255, 255],
  offWhite: [247, 247, 247],
  green: [34, 247, 19],
  gray: [188, 188, 188],
  yellow: [242, 255, 66],
};
