// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/functions";
import "firebase/auth";
import { IDealsForBots, IUserBotsAndActiveDealsInfo } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyA1-VUTffmW88oz2FHWUSwUD-3jvNG0L8Y",
  authDomain: "three-commas-helper.firebaseapp.com",
  projectId: "three-commas-helper",
  storageBucket: "three-commas-helper.appspot.com",
  messagingSenderId: "1025260244500",
  appId: "1:1025260244500:web:8be2aef80ec564ff9f2101",
  measurementId: "G-F79Z0SF99J",
};
firebase.initializeApp(firebaseConfig);
const cloudFunctions = firebase.functions();

class ThreeCommasService {
  private getAllUsersInfoGenerator = () =>
    cloudFunctions.httpsCallable("getAllUsersInfo");

  private getClosedDealsForBotsGenerator = () =>
    cloudFunctions.httpsCallable("getClosedDealsForBots");

  getAllUsersInfo = async (): Promise<IUserBotsAndActiveDealsInfo[]> => {
    const send = this.getAllUsersInfoGenerator();
    const result = await send();
    return result.data;
  };

  getClosedDealsForBots = async (
    botsToFetch: { botId: string; userName: string }[],
    scope: "active" | "finished" | "completed" | "cancelled" | "failed",
    order:
      | "created_at"
      | "updated_at"
      | "closed_at"
      | "profit"
      | "profit_percentage",
    limit?: number
  ): Promise<{ data: IDealsForBots }> => {
    const send = this.getClosedDealsForBotsGenerator();
    const result = await send({
      botsToFetch,
      scope,
      order,
      limit,
    });
    return result.data;
  };

  universallyAuthenticate = async (password: string) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword("mschmuckler854@gmail.com", password);
  };

  onAuthStateChanged = (
    callback: (user: firebase.User | null) => void
  ): void => {
    firebase.auth().onAuthStateChanged(callback);
  };

  logout = () => {
    firebase.auth().signOut();
  };
}

export const ThreeCommasAPI = new ThreeCommasService();
